/* eslint-disable prefer-promise-reject-errors */
import 'isomorphic-fetch';
import { defaultFailedResponse } from '@components/auth/fetchData';

export function firstLetterUpperCase(str) {
    if (!str) return str;

    const newStr = str.toString();
    const splitStr = newStr.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i += 1) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
}

function isEncodedString(str) {
    try {
        window.atob(str);
        return true;
    } catch (e) {
        // something failed
        return false;
        // if you want to be specific and only catch the error which means
        // the base 64 was invalid, then check for 'e.code === 5'.
        // (because 'DOMException.INVALID_CHARACTER_ERR === 5')
    }
}

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function isNotEmptyObj(obj) {
    try {
        if (obj !== undefined && obj !== null && obj !== '' && obj !== 'undefined') {
            if (isEncodedString(obj) && isJsonString(window.atob(obj))) {
                if (Object.keys(JSON.parse(window.atob(obj))).length > 0) {
                    return true;
                }
                return false;
            }
            if (Object.keys(obj).length > 0) {
                return true;
            }
            return false;
        }
        return false;
    } catch (err) {
        return false;
    }
}

export function replaceAll(subject, search, replacement) {
    return subject.replace(new RegExp(search, 'g'), replacement);
}

// Youtube Video Pause on Click
export function youtubePause() {
    const video = document.getElementsByClassName('YoutubeVideo');

    if (!video) return;

    video.forEach(vid => {
        vid.contentWindow.postMessage(`{"event":"command","func":"pauseVideo","args":""}`, '*');
    });
}

export function getLogin() {
    if (
        isNotEmptyObj(sessionStorage.getItem('login_details')) &&
        JSON.parse(sessionStorage.getItem('login_details')).account_type !== undefined
    ) {
        return JSON.parse(sessionStorage.getItem('login_details'));
    }
    return undefined;
}

export function formatCurrency(amount) {
    let x = amount.toString();
    let afterPoint = '';
    if (x.indexOf('.') > 0) afterPoint = x.substring(x.indexOf('.'), x.length);
    x = Math.floor(x);
    x = x.toString();
    let lastThree = x.substring(x.length - 3);
    const otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== '') lastThree = `,${lastThree}`;
    const res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree + afterPoint;
    return res;
}

export const nodeFetch = async ({ path = '', method = 'POST', data = {} }) => {
    const userData = JSON.stringify({ ...data });
    const headers = {
        'Content-Type': 'application/json',
        'X-Request-Fetch': 1,
        'X-Requested-With': 'XMLHttpRequest'
    };
    const fetchOptions = {
        method,
        credentials: 'include',
        body: userData,
        headers
    };

    let response = '';
    if (method === 'GET') {
        response = await fetch(path, { headers });
    } else {
        response = await fetch(path, fetchOptions);
    }

    const res = await response
        .json()
        .catch(/* added in cases where response is not json and dot json call fails */ () => defaultFailedResponse);
    if (res.code === 'success') {
        return res.data;
    }
    throw new Error('failed');
};

export const cancelablePromise = promise => {
    let hasCanceled = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            value => (hasCanceled ? reject({ isCanceled: true, value }) : resolve(value)),
            error => reject({ isCanceled: hasCanceled, error })
        );
    });

    return {
        promise: wrappedPromise,
        cancel: () => {
            hasCanceled = true;
        }
    };
};

export function dateCompare(x) {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();
    const t = `${yyyy}-${mm}-${dd}`;
    const difference = Math.floor((Date.parse(t) - Date.parse(x)) / 86400000);
    return difference < 10 ? `0${difference}` : difference;
}
